<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">あなたが司書課程を履修した動機について、もっとも近いものをお選びください。図書館情報学の専門課程（筑波大学や慶應義塾大学など）を卒業された方は、課程を選んだ理由についてお答えください。【24項目】<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserScaleAnswerCard
					v-for="(item, index) in scaleitemorders"
					:key="'card-' + item.scaleitem.id"
					:scaleitem="item.scaleitem"
					:index="index + 1"
				></UserScaleAnswerCard>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'
import UserScaleAnswerCard from '@/components/UserScaleAnswerCard'

export default {
	name: 'UserSurvey',
	components: {
		UserScaleAnswerCard
	},
	data () {
		return {
			scaleitemorders: [],
			form: {
				motivation: null,
				certificate_year: null
			},
			rules: {
				required: value => !!value || '必須'
			},
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			],
			certificate_years: [
				{ text: "1970年代", value: 1 },
				{ text: "1980年代", value: 2 },
				{ text: "1990年代", value: 3 },
				{ text: "2000年代", value: 4 },
				{ text: "2010年代", value: 5 },
				{ text: "2020年代", value: 6 },
			]
		}
	},
	async created () {
		this.setLoading(true)
		this.clearScaleScore()
		const scaleId = this.test.scales[0].id
		await this.getScale(scaleId).then(() => {
			this.clearScaleItemAnswers()
			this.clearAnsweredScales()
			this.setScaleItemNotAnswered(this.scale.scaleitemorders)
			this.setLoading(false)
			this.scaleitemorders = this.scale.scaleitemorders
			this.startAt = Date.now()
		}).catch(err => {
			this.clearScale()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		}
	}
}
</script>